<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    variant="white"
    opacity="0.85"
    spinner-variant="primary"
  >
    <b-container v-if="ready" class="d-flex flex-wrap justify-content-lg-between">
      <div
        class="promotion-image"
        :style="{backgroundImage: 'url(' + promotionData.photo + ')'}"
      />
      <div class="d-flex flex-column w-100">
        <div class="promotion-title d-flex w-100 justify-content-between align-items-center">
          <h3>
            {{ promotionData.title }}
          </h3>
          <p class="promotion-title-date">
            {{ promotionData.date | getDate }}
          </p>
        </div>
        <div class="promotion-body">
            <div v-html="formattedPromotionContent.outerHTML"></div>
          <b-button
            class="align-self-center"
            :to="promotionData.buttonAction.url"
            v-if="promotionData.buttonAction.type === 'internal'"
          >
            {{ promotionData.buttonTitle }}
          </b-button>
          <b-button
            v-else
            class="align-self-center"
            :href="promotionData.buttonAction.url"
            target="_blank"
          >
            {{ promotionData.buttonTitle }}
          </b-button>
        </div>
      </div>
    </b-container>
  </b-overlay>
</template>

<script>
import { getPromotion } from '../api/profi_club/promotions';

export default {
  name: 'Promotion',
  data() {
    return {
      promotionData: null,
      isLoading: true,
      error: null,
    };
  },
  computed: {
    ready() {
      return this.error === null && this.promotionData !== null;
    },
    formattedPromotionContent() {
      const div = document.createElement('div');
      div.innerHTML = this.promotionData.content.replaceAll('\n', '<br>');
      return div;
    },
  },
  async mounted() {
    const resp = await getPromotion(this.$route.params.id);
    if (resp === null) {
      this.error = 'Ошибка';
      this.isLoading = false;
    } else {
      this.promotionData = resp;
      this.isLoading = false;
    }
  },
};
</script>

<style scoped lang="scss">
.promotion-image {
  margin-top: 22px;
  margin-bottom: 30px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;

  &::before {
    content: '';
    padding-top: 56%;
    display: block;
  }
}

.promotion-title {
  margin-bottom: 30px;

  h3 {
    font-weight: 700;
  }

  &-date {
    margin: 0;
    font-style: italic;
    color: #404040;
    font-size: 12px;
  }
}

.promotion-body {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  .btn {
    width: 30%;
    min-width: 200px;
    margin: 50px 0 50px 0;
    background-color: #0050C8;
    gap: 10px;
    border-radius: 4px;
  }
}

@media (max-width: 576px) {
  .promotion-body .btn {
    width: 100%;
  }
  .promotion-title {
    margin-bottom: 30px;
    h3 {
      font-size: 20px;
      font-weight: 700;
    }
  }
}
</style>
